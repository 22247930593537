import { createAction, props } from '@ngrx/store';

// Define actions for user management

export const getUsersData = createAction(
  '[User Details] Get Users Data',
  props<{
    order: string;
    pageSize: number;
    page: number;
    selectedAccountIds?: Array<string>;
    label?: string;
    searchText?: string;
  }>()
);
export const getUsersDataSuccess = createAction(
  '[User Details] Get Users Data Success',
  props<{ usersData: any }>()
);
export const getUsersDataFailure = createAction(
  '[User Details] Get Users Data Failure'
);

export const getUserDetailsData = createAction(
  '[User Details] Get Users Details Data',
  props<{
    params: any;
    practiceOrgId: string | undefined;
  }>()
);
export const getUserDetailsDataSuccess = createAction(
  '[User Details] Get User Details Data Success',
  props<{ userDetailsData: any }>()
);
export const getUserDetailsDataFailure = createAction(
  '[User Details] Get User Details Data Failure'
);

export const emptyUsersAndUserDetailsData = createAction(
  '[Users and User Details] Empty Users and User Details Data'
);

export const getAssociatedFacilities = createAction(
  '[Permissions] Get Associated Facilities',
  props<{
    practiceOrgId: string;
    calledAfterUserDetailsAPI?: boolean;
    userDetailsData?: any;
  }>()
);
export const getAssociatedFacilitiesSuccess = createAction(
  '[Permissions] Get Associated Facilities Success',
  props<{ associatedFacilities: any }>()
);
export const getAssociatedFacilitiesFailure = createAction(
  '[Permissions] Get Associated Facilities Failure'
);

export const getUserManagementPermissionObj = createAction(
  '[Permissions] Get User Management Permission Object',
  props<{
    activeTab?: string;
    associatedFacilities?: any;
    permissionObject?: any;
  }>()
);

export const toggleActiveTab = createAction(
  '[Permissions] Toggle Active Tab',
  props<{ activeTab: string }>()
);

export const savePermissions = createAction(
  '[Permissions] Save Permissions',
  props<{
    emailId: string;
  }>()
);
export const savePermissionsSuccess = createAction(
  '[Permissions] Save Permissions Success',
  props<{ savePermissionData: any }>()
);
export const savePermissionsFailure = createAction(
  '[Permissions] Save Permissions Failure'
);
