import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsermanagementState } from './user-management.reducer';

// Define the feature selector for UsermanagementState
export const selectUsermanagementState =
  createFeatureSelector<UsermanagementState>('usermanagement');

export const selectUserDetailsLoading = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.userDetailsLoading
);

export const selectUsersData = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.usersData
);

export const selectUserDetailsData = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.userDetailsData
);

export const selectAssociatedFacilities = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.associatedFacilities
);

export const selectActiveTab = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.activeTab
);

export const selectUserManagementPermissionObj = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.userManagementPermissionObj
);

export const selectSavePermissionsLoading = createSelector(
  selectUsermanagementState,
  (state: UsermanagementState) => state.savePermissionLoading
);
